<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :documentInfo.sync="documentInfo"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'process-manual',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      documentInfo: {
        documentClassCd: 'PLME', // process menaul
        title: '공정설명서',
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/psi/documentMaster.vue');
      this.documentInfo.title = this.$language('공정설명서'); // 공정설명서
    },
    reset() {
      Object.assign(this.$data.documentInfo, this.$options.data().documentInfo);
    },
  }
};
</script>